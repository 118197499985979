<template>
  <div class="readme-article">
    <h1 id="招生">1.招生</h1>
    <h2 id="线索渠道">1.1线索渠道</h2>
    <p>
      线索来源渠道设置，例如：某某平台、电销、网络、朋友介绍等。
    </p>
    <p>
      <img src="@/assets/img/recruit/config1-1.png" />
    </p>
    <h2 id="线索意向状态">1.2线索意向状态</h2>
    <p>
      线索意向状态设置，例如：优质客户、普通客户、潜在客户、不可挖掘客户等。
    </p>
    <p>
      <img src="@/assets/img/recruit/config1-2.png" />
    </p>
    <h2 id="回访联系结果">1.3回访联系结果</h2>
    <p>
      回访联系结果设置，例如：来访、来电、空号、流失等。
    </p>
    <p>
      <img src="@/assets/img/recruit/config1-3.png" />
    </p>
    <h2 id="回访常用语">1.4回访常用语</h2>
    <p>
      回访常用语设置，具体示例如下图。
    </p>
    <p>
      <img src="@/assets/img/recruit/config1-4.png" />
    </p>
    <h2 id="扩展标记">1.5扩展标记</h2>
    <p>
      扩展标记设置，具体示例如下图。
    </p>
    <p>
      <img src="@/assets/img/recruit/config1-5.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit0-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>